import React from "react"
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import Layout from "../components/layout"
import Hero from "../components/hero"
import Sectionheader from "../components/sectionheader"
import Area from "../components/area"
import * as styles from '../styles/home.module.css'

export default function Home({ data }) {
  return(
    <Layout>
      <Hero h1="SMART Innovative Technologies Division" sub="Transformation through technology" />
      <div className={styles.who}>
        <div className="container">
          <div className="row align-items-start">
            <Sectionheader name="Who We Are" />
            <div className="col-11 col-sm-8">
              <p>TSU SMART Global Innovative Technologies Division is a state-of-the-art center established to promote digital literacies, to inspire SMART connected innovations, and to provide education technology skills across the spectrum of education &#40;PreK-Life Long Learning&#41; and all disciplines, in preparation for the digital workforce and communities.</p>
              <h3>Our Mission&#58;</h3>
              <p>The center&apos;s mission is to address diversity of the digital divide and to increase the number of minorities and underserved students in STEM and STEAM Programs for the digital workforce</p>
            </div>
            <div className="col-11 col-sm-3">
              <div className={styles.whocontact}>
                <ul>
                  <li>
                    <a href="mailto:smartcenter@tnstate.edu" className="btn btn-primary">Request Information</a>
                  </li>
                  <li>
                    <a href="mailto:smartcenter@tnstate.edu" className="btn btn-primary">Contact Us</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.quote}>
        <div className="container">
        <div className="row align-items-center">
        <div className="col-12 col-sm-3">
        <Img fluid={data.file.childImageSharp.fluid} className="smart-circle-frame"/>
        <span>Tim Cook</span>
        </div>
          <div className="col-12 col-sm-9">
          <blockquote cite="https://twitter.com/tim_cook/status/1153708666584891392">
            Anything is possible when people come together with a shared vision. Thank you <a href="https://twitter.com/tsuedu?lang=en" target="_blank" rel="noreferrer">&#64;TSUedu</a> for your leadership and enthusiasm in bringing coding to your community and HBCUs nationwide!
          </blockquote>
          </div>
          </div>
        </div>
      </div>

      <div className={styles.areas}>
        <div className="container">
            <div className="row">
              <Sectionheader name="Applied Areas of Technology Innovations" />
              <Area />
            </div>
          </div>
        </div>
    </Layout>
  )
}

export const query = graphql`
query Cook {
  file(relativePath: {eq: "83tVAzyy_400x400.jpg"}) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
  }
}

`