import React from 'react'
import * as styles from '../styles/areas.module.css'
import { Link } from 'gatsby'

const areas = [
    {
        title: 'SMART Innovations',
        img: 'thumbs/AdobeStock_185744578.jpeg',
        copy: "The exploration, research, design, and evaluation of SMART Connected Mobile and Wearable Technologies to improve and enhance teaching, learning, and the workforce. This includes the investigation of immersive learning using the innovation of Mixed Reality Technologies of virtual (VR), augmented (AR) and holograms (HR) simulations",
        linkcopy: 'Learn More',
        link: 'https://ai-tnstatesmartcenter.org',
        external: false,
        key: 1
    },
    {
        title: 'Artificial Intelligence (AI)',
        img: 'thumbs/ArtificialIntelligence.jpeg',
        copy: 'The SMART Center provides A.I. training and resources for the campus and community. Faculty can learn to incorporate A.I. in teaching and research. Students can gain skills through new A.I. tools and hands-on projects.',
        linkcopy: 'Learn More',
        link: 'https://ai-tnstatesmartcenter.org/artificial-intelligence',
        external: false,
        key: 2
    },
    {
        title: 'Academic Esports',
        img: 'thumbs/esport.jpg',
        copy: 'To educate and support students, faculty, and communities in playing, designing, coding, composing, managing, and coaching of eSports as a gateway to STEAM (Science, Technology, Engineering, Arts, and Math) programs and to become digital innovators and entrepreneurs. Go TSU eTigers!',
        linkcopy: 'Come Play and Learn',
        link: 'https://ai-tnstatesmartcenter.org/academic-esports',
        external: false,
        key: 3
    },
    {
        title: 'Open Educational Resources',
        img: 'thumbs/oer.jpg',
        copy: 'Open Educational Resources (OER) seeks to re-tool and re-skill alumni with professional development skills that will help them be better prepared for an innovative new world of technology',
        linkcopy: 'Learn More',
        link: '/oer',
        external: false,
        key: 4
    },
    {
        title: 'Alumni Programs',
        img: 'thumbs/alumni.jpg',
        copy: 'The vision of Alumni Programs is to empower the alumni, who are our global and community leaders, by strengthening individual success through re-tooling and re-skilling modern skills them with that will create confidence in the work place and society',
        linkcopy: 'Learn More',
        link: '/alumni',
        external: false,
        key: 5
    },
    {
        title: 'HBCU C2',
        img: 'thumbs/app-dev.jpg',
        copy: "HBCU C2 is a national teaching and learning initiative that promotes innovative, educational equity, and aims to address community challenges using app design and Apple's Swift programming language",
        linkcopy: 'Learn More',
        link: 'https://www.hbcuc2.org',
        external: true,
        key: 6
    },
    {
        title: 'Global Online',
        img: 'thumbs/smart-hero.jpg',
        copy: "To sustain its commitment to ease of learning for a global community, TSU Global online offers a variety of online educational opportunities. Programs offered are designed to meet the professional and personal needs of online learners worldwide.",
        linkcopy: 'Learn More',
        link: 'https://www.tnstate.edu/online/',
        external: true,
        key: 7
    },
    {
        title: 'Continuing Education',
        img: 'thumbs/conted.jpg',
        copy: "To sustain its commitment to promoting lifelong learning to a global community, Tennessee State University partners with employers, faculty, and local organizations to offer a variety of non-credit educational opportunities.",
        linkcopy: 'Learn More',
        link: 'https://www.tnstate.edu/continuinged/',
        external: true,
        key: 8
    }
]

export default function Area() {

    return(
        <div className="col-11 align-self-end row justify-content-end" data-masonry='{"percentPosition": true }'>
            {areas.map((area) => {
                return (
                    <div key={area.key} className="col-md-5 col-10">
                        <div className={styles.area}>
                            <h3>{area.title}</h3>
                            <div className="row align-items-end">
                                <div className="col-lg-5 col-12">
                                    <div className={styles.areaimage}>
                                        <img src={area.img} alt={area.title} />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-12">
                                    <div >
                                        <p>{area.copy}</p>
                                        {
                                            area.external !== true &&
                                            <Link to={area.link} className="btn btn-outline-primary">{area.linkcopy}</Link>
                                        }
                                        {
                                            area.external === true &&
                                            <a href={area.link} className="btn btn-outline-primary" target="_blank" rel="noreferrer">{area.linkcopy}</a>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}
