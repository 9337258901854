import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import * as styles from '../styles/hero.module.css'
import HeroHeadline from '../components/heroheadline'

export default function Hero() {
    const data = useStaticQuery(graphql`
        query SmartHero {
            file(relativePath: {eq: "AdobeStock_343016778.jpeg"}) {
                id
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)

    return (
        <div className={styles.smarthero}>
            <Img fluid={data.file.childImageSharp.fluid} className={styles.smartheroimage}/>
            <HeroHeadline h1="SMART Innovative Technologies Division" sub="transformation through technology" />
        </div>
    )
}
